<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/evaluate_on_desk1.jpg')"
      class="white--text"
      gradient="to right, rgba(0, 0, 0, .8), rgba(24, 61, 114, .9)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="1024"
          width="100%"
        >
          <base-heading :title="$t('brand.tagline')" />

          <base-body>
            {{ $t('brand.about') }}
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <span class="font-weight-bold mr-4 my-4">{{ $t('expr.discover') }}</span>

            <base-btn :to="`/${this.$i18n.locale}/due-diligence`" class="mr-4">
              {{ $t('button.due_diligence') }}
            </base-btn>

            <span class="font-weight-bold mr-4 my-4">{{ $t('expr.and') }}</span>

            <base-btn :to="`/${this.$i18n.locale}/reporting`" class="mr-4">
              {{ $t('button.reporting') }}
            </base-btn>

            <span class="font-weight-bold mr-4 my-4">{{ $t('expr.or') }}</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              color="white"
              text
              :to="`/${this.$i18n.locale}/contact-us`"
            >
              {{ $t('button.get_in_touch') }}
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
